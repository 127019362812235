var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/magazzino" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "box-archive" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.magazzino")
                    ),
                  },
                },
                [_vm._v("Magazzino")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN, _vm.WAREHOUSE_WORKER])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/alloggiamento" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "cube" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.alloggiamento")
                    ),
                  },
                },
                [_vm._v("Alloggiamento")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/vassoio-workbox" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "rectangle-list" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.vassoioWorkbox")
                    ),
                  },
                },
                [_vm._v("Vassoio Workbox")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN, _vm.MANAGER])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/impostazioni-scorte" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "eye" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.impostazioniScorte")
                    ),
                  },
                },
                [_vm._v("Impostazioni Scorte")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/identifier" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "fingerprint" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.identifier")
                    ),
                  },
                },
                [_vm._v("Identifier")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }