import { Authority } from '@/shared/security/authority';

const JhiDocsComponent = () => import('@/admin/docs/docs.vue');
const JhiTrackerComponent = () => import('@/admin/tracker/tracker.vue');

export default [
  {
    path: '/admin/docs',
    name: 'JhiDocsComponent',
    component: JhiDocsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/tracker',
    name: 'JhiTrackerComponent',
    component: JhiTrackerComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
];
