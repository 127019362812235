import Vuex from 'vuex';
import VueI18n, { DateTimeFormats } from 'vue-i18n';
import JhiFormatter from './formatter';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faFileExport } from '@fortawesome/free-solid-svg-icons/faFileExport';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons/faIdBadge';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faHomeUser } from '@fortawesome/free-solid-svg-icons/faHomeUser';
import { faLineChart } from '@fortawesome/free-solid-svg-icons/faLineChart';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons/faWarehouse';
import { faShirt } from '@fortawesome/free-solid-svg-icons/faShirt';
import { faGears } from '@fortawesome/free-solid-svg-icons/faGears';
import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons/faMoneyBillTransfer';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons/faRectangleList';
import { faCubesStacked } from '@fortawesome/free-solid-svg-icons/faCubesStacked';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faCircleNodes } from '@fortawesome/free-solid-svg-icons/faCircleNodes';
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons/faBoxArchive';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons/faFingerprint';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons/faAddressBook';
import { faBookmark } from '@fortawesome/free-solid-svg-icons/faBookmark';
import { faBookBible } from '@fortawesome/free-solid-svg-icons/faBookBible';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faFileImport } from '@fortawesome/free-solid-svg-icons/faFileImport';
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons/faScrewdriver';
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons/faHeartPulse';
import { faGlasses } from '@fortawesome/free-solid-svg-icons/faGlasses';
import { faPenRuler } from '@fortawesome/free-solid-svg-icons/faPenRuler';
import { faInbox } from '@fortawesome/free-solid-svg-icons/faInbox';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons/faLessThanEqual';

import VueCookie from 'vue-cookie';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';

import * as filters from '@/shared/date/filters';
import { accountStore } from '@/shared/config/store/account-store';
import { translationStore } from '@/shared/config/store/translation-store';

const dateTimeFormats: DateTimeFormats = {
  it: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  fr: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  de: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  es: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  // jhipster-needle-i18n-language-date-time-format - JHipster will add/remove format options in this object
};

export function initVueApp(vue) {
  vue.use(VueCookie);
  vue.use(Vuelidate);
  vue.use(Vue2Filters);
  filters.initFilters();
}

export function initFortAwesome(vue) {
  library.add(
    faArrowLeft,
    faAsterisk,
    faBan,
    faBars,
    faBell,
    faBook,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimes,
    faTimesCircle,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faFileExport,
    faIdBadge,
    faBuilding,
    faUsersCog,
    faUserFriends,
    faHomeUser,
    faLineChart,
    faBell,
    faWarehouse,
    faShirt,
    faGears,
    faMoneyBillTransfer,
    faCode,
    faCube,
    faRectangleList,
    faCubesStacked,
    faGlobe,
    faCircleNodes,
    faBoxArchive,
    faFingerprint,
    faEye,
    faAddressBook,
    faBookmark,
    faBookBible,
    faBookOpen,
    faFileImport,
    faHeartPulse,
    faScrewdriver,
    faGlasses,
    faPenRuler,
    faInbox,
    faFilter,
    faCircleXmark,
    faCircleCheck,
    faRotate,
    faLessThanEqual
  );
}

export function initI18N(vue) {
  vue.use(VueI18n);
  return new VueI18n({
    dateTimeFormats,
    silentTranslationWarn: true,
    formatter: new JhiFormatter(),
  });
}

export function initVueXStore(vue) {
  vue.use(Vuex);
  return new Vuex.Store({
    modules: {
      accountStore,
      translationStore,
    },
  });
}
