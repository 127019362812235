var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/people" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "user" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.people")),
              },
            },
            [_vm._v("People")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/people-metadata" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "users-cog" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.peopleMetadata")
                ),
              },
            },
            [_vm._v("People Metadata")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/badge" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "id-badge" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.badge")),
              },
            },
            [_vm._v("Badge")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/group" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "home-user" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.group")),
                  },
                },
                [_vm._v("Group")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/department" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "building" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.department")
                    ),
                  },
                },
                [_vm._v("Department")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/group-permission" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "user-friends" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.groupPermission")
                ),
              },
            },
            [_vm._v("Group Permission")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/people-metadata-condition" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "less-than-equal" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.peopleMetadataCondition")
                    ),
                  },
                },
                [_vm._v("People Metadata Condition")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }